<template>
    <header id="header">
        <TopBar v-if="isDefaultHeader || showTopBar" />
        <!-- Change layout according to headerVariant value (default or cooperation) -->
        <div id="mainHeader" class="container">
            <template v-if="isDefaultHeader">
                <Logo class="main-logo" :href="platformLogoHref" />

                <component :is="headingTag" class="main-header">
                    {{ $t('Header.text') }}
                </component>

                <!--<div class="separator"></div>-->
                <HeaderBadge
                    :header="$t('Header.trustami')"
                    class="ml-auto"
                    v-if="$store.state.ident !== 'www.animod.nl'"
                >
                    <Trustami />
                </HeaderBadge>

                <slot name="default.append"></slot>
            </template>
            <!-- Cooperation header -->
            <template v-else>
                <!-- Require statement doesn't work with variables (webpack needs to know the extension) -->
                <!-- Therefore having a partner logo in public folder can be a workaround -->
                <Logo v-if="platformLogoUrl" class="main-logo" :src="platformLogoUrl" :href="platformLogoHref" />
                <div v-if="platformLogoUrl" class="separator"></div>
                <Logo
                    cooperation-text
                    class="coop-logo"
                    :href="platformLogoHref"
                    :class="{ 'mr-auto': !showCoopText }"
                />

                <span v-if="showCoopText" class="header__coop-text">{{ $t('Header.coopText') }}</span>

                <CustomerService v-if="showCustomerService" class="cooperation-service">
                    <template v-slot:prepend>
                        <IconSvg filepath="phone.svg" class="phone-svg" />
                    </template>
                </CustomerService>

                <slot name="cooperation.append"></slot>
            </template>
        </div>
        <!--
            the following logos should be thought out with responsive breakpoints
        -->
    </header>
</template>

<script>
import Logo from '@/components/Logo';
import TopBar from '@/components/header/TopBar';
import CustomerService from '@/components/header/CustomerService';
import HeaderBadge from '@/components/header/HeaderBadge';
import Trustami from '@/components/header/Trustami';
import headerCommon from '@/mixins/headerCommon';

export default {
    name: 'Header',
    mixins: [headerCommon],
    components: {
        HeaderBadge,
        Logo,
        TopBar,
        CustomerService,
        Trustami
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

#header {
    background: var(--header-background);
}

#mainHeader {
    color: var(--header-color);
    height: var(--header-desktop-height);
    display: flex;
    align-items: center;
    margin: 0 auto;

    .main-logo {
        padding: var(--logo-main-padding-desktop);
        width: var(--logo-main-width-desktop);
        height: var(--logo-main-height-desktop);
    }

    .coop-logo {
        height: var(--logo-coop-height-desktop);
        width: var(--logo-coop-width-desktop);
        overflow: hidden;
        position: relative;

        &:not(:first-child) {
            padding: 0 variables.$grid-padding;
        }
    }

    .main-header {
        font-size: 17px;
        margin: 0 15px;
        font-weight: normal;
    }

    > .separator {
        border-left: 1px solid var(--border-color);
        height: variables.$badgeSeparatorHeight;
        flex-basis: 1px;
        flex-grow: 0;
    }

    .cooperation-service {
        position: relative;
        padding-left: 25px;

        .phone-svg {
            color: var(--primary);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            font-size: 0.9em;
        }
    }

    .header__coop-text {
        color: var(--primary);
        font-size: 16px;
        margin: auto;
        font-weight: bold;
    }
}
</style>
