//included into iframe themes frameset.html
var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
const IFRAME_ID = 'animod_frame';
const DOMAIN_PART_LIVE = 'animod.'; //.de or .com
const DOMAIN_PART_STAGING = 'anilabs.de';

// Listen for a message from the iframe.
eventer(
    messageEvent,
    function (e) {
        if (
            !e.origin.includes(DOMAIN_PART_LIVE) &&
            !e.origin.includes(DOMAIN_PART_STAGING) &&
            window.origin !== e.origin
        ) {
            return;
        }
        if (isNaN(e.data) && typeof e.data === 'string') {
            //vue extension sends messages as well, but data is of type Object
            const iframe = getIframe();
            if (e.data.indexOf('resize:') === 0 && iframe) {
                iframe.style.height = e.data.substring(7) + 'px';
            } else if (e.data.indexOf('scroll:') === 0) {
                window.scrollTo(0, parseInt(e.data.substring(7)));
            } else if (e.data.indexOf('scrollToBottom:') === 0) {
                window.scrollTo(0, document.body.scrollHeight);
            }
        }
    },
    false
);

document.addEventListener('DOMContentLoaded', () => {
    const iframe = getIframe();
    if (iframe && !iframe.src) {
        iframe.setAttribute('src', location.origin + location.search);
    }
});

function getIframe() {
    let iframe = document.getElementById(IFRAME_ID);
    if (!iframe) {
        iframe =
            document.querySelector(`iframe[src*="${DOMAIN_PART_LIVE}"]`) ||
            document.querySelector(`iframe[src*="${DOMAIN_PART_STAGING}"]`);
    }
    return iframe;
}
