import themeableLayout from './themeableLayout';

export class Message {
    constructor(name, value) {
        Object.assign(this, {
            event: name,
            value
        });
    }
}

export const middleware = ({ redirect, req, $config, store }, mockFrameName) => {
    /**
     * https://developer.chrome.com/blog/referrer-policy-new-chrome-default
     * Cross-origin referrer-policy is restricted. Make sure to add referrerpolicy="no-referrer-when-downgrade" to iframe element
     */
    if (process.env.NODE_ENV !== 'production' || $config.forceIframe) {
        if (process.server && !(req.headers.referer && req.headers.referer.includes(mockFrameName))) {
            redirect(`/${mockFrameName}?x-ident=${store.state.ident}`);
        }
    }
};

export default {
    data() {
        return {
            ...themeableLayout.data,
            previousPageId: null,
            mutationObserver: null,
            resizeObserver: null,
            resizeTimeout: null,
            removeAfterRouteHook: null,
            iframeOffsetInParent: 0,
            iframeID: 'animod_frame',
            scrollIFrameOnRoute: true,
            parentViewportHeight: null
        };
    },
    computed: {
        ...themeableLayout.computed,
        inIframe() {
            return this.isInsideIframe();
        },
        theme() {
            return this.$store.state.theme;
        },
        outsideIframe() {
            //needed for the gtm
            return !this.inIframe;
        }
    },
    methods: {
        ...themeableLayout.methods,
        //left for backwards compatibility. Posts messages in a form of a string. Partners still listen to string messages
        postParent(command, value, timeout) {
            setTimeout(function () {
                parent.postMessage(command + ':' + value, '*');
            }, timeout);
        },
        postFrame(message) {
            const postMessageAvailable = this.$getSafe(
                document.getElementById(this.iframeID) || {},
                'contentWindow.postMessage'
            );
            postMessageAvailable
                ? postMessageAvailable(message)
                : this.$isDev
                ? console.log(`Cannot tell animod frame to ${message.event}`)
                : false;
        },
        postResizeToParent(height, timeout = 0) {
            //30 offset is needed. Cannot observe #animod instead of #main, since observer won't be fired
            const currentHeight = (height || document.body.scrollHeight) + 30;
            this.postParent('resize', currentHeight, timeout);
        },
        postScrollToParent(offset = this.iframeOffsetInParent) {
            this.postParent('scroll', offset, 100);
        },
        postScrollParentToBottom() {
            this.postParent('scrollToBottom');
        },
        postRouteChangeToParent(newPath) {
            //Omitting timeout
            parent.postMessage(new Message('pushState', newPath), '*');
        },
        postBackNavigationToIframe() {
            this.postFrame(new Message('popState'));
        },
        frameAppendRouteChangeHandler() {
            this.removeAfterRouteHook = this.$router.afterEach((to, from, next) => {
                this.$isDev && console.log(`in iframe: ${this.inIframe}, to: ${to.fullPath}`);

                this.postRouteChangeToParent(to.fullPath);
            });
        },
        frameAppendBackMessageHandler() {
            window.addEventListener('message', e => {
                if (e.origin !== window.origin) {
                    return;
                }

                if (e.data.event === 'popState') {
                    this.$router.back();
                }
            });
        },
        parentAppendRouteChangeHandler() {
            window.addEventListener('message', e => {
                if (e.origin !== window.origin) {
                    return;
                }
                if (e.data.event === 'pushState') {
                    //this.$router.push(e.data.value);
                    window.history.replaceState(
                        {
                            //some state data
                        },
                        '',
                        e.data.value
                    );
                }
            });
        },
        parentAppendBackHandler() {
            window.addEventListener('popstate', this.postBackNavigationToIframe);
            /*window.addEventListener('popstate', event => {
                this.postFrame(new Message('popState'));


                // The popstate event is fired each time when the current history entry changes.

                /!*var r = confirm("You pressed a Back button! Are you sure?!");

                if (r == true) {
                    // Call Back button programmatically as per user confirmation.
                    history.back();
                    // Uncomment below line to redirect to the previous page instead.
                    // window.location = document.referrer // Note: IE11 is not supporting this.
                } else {
                    // Stay on the current page.
                    history.pushState(null, null, window.location.pathname);
                }

                history.pushState(null, null, window.location.pathname);*!/

            }, false);*/
        },
        parentAppendProductFooterHandler() {
            const iframe = document.getElementById(this.iframeID);
            if (iframe) {
                window.addEventListener('scroll', e => {
                    this.postFrame(new Message('scroll', window.scrollY - iframe.offsetTop));
                });
            }
        },
        isInsideIframe() {
            try {
                //TODO: get isIframe on a server side
                // The trick with ?iframe=true does not work as partners like lidlreisen do not set this parameter
                /*if (process.server) {
                    return !!this.$nuxt.context.headers.referer;
                }*/
                return window.self !== window.top;
                //return window.self !== window.top;
            } catch (e) {
                //server side will also return true
                return true;
            }
        },
        isWebView() {
            // USe with caution. User-agent parsing is unreliable
            if (typeof window === undefined) {
                return false;
            }

            const navigator = window.navigator;

            const standalone = navigator.standalone;
            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent);
            const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
            const ios_ipad_webview = ios && !safari;

            return ios ? (!standalone && !safari) || ios_ipad_webview : userAgent.includes('wv');
        },
        initMetaTagsCommunication() {
            if (this.inIframe) {
                this.$root.$on('iframeHeadChange', meta => {
                    parent.postMessage(new Message('meta', meta), '*');
                });
            } else {
                const { set, getOptions } = this.$meta().addApp('parentFrame');

                window.addEventListener('message', e => {
                    if (e.origin !== window.origin) {
                        return;
                    }
                    if (
                        typeof e.data.event === 'string' &&
                        e.data.event.includes('meta') &&
                        typeof e.data.value === 'object'
                    ) {
                        delete e.data.value.link; // Important. Otherwise overwrites favicons
                        set(e.data.value);
                    }
                });
            }
        },
        fixPositionInIframe(bottomFixedElement, overrideOffset = 0) {
            let scrollStart = true;
            let timeout = null;
            const layout = this.$nuxt.iframeLayout;
            const self = this;

            bottomFixedElement.previousElementSibling.style.marginBottom = '50px';
            bottomFixedElement.style.transition = 'opacity .1s ease-in-out';
            bottomFixedElement.style.position = 'fixed'; // Test with caution, can be applied to sticky elements, e.g. UpsellingFooter mobile

            if (layout) {
                window.addEventListener('message', scrollHandler);
                this.$on('hook:beforeDestroy', () => {
                    window.removeEventListener('message', scrollHandler);
                });
                alignToBottom();
            }

            function scrollHandler(e) {
                // Cannot use e.origin due to the numerous frame hosts
                if (!e.data || e.data.event !== 'scroll' || isNaN(e.data.value)) {
                    return;
                }
                if (scrollStart) {
                    bottomFixedElement.style.opacity = 0;
                    scrollStart = false;
                }

                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    alignToBottom(e.data.value, e.data.innerHeight);
                    bottomFixedElement.style.opacity = 1;
                    scrollStart = true;
                }, 300);
            }
            function alignToBottom(scrollTop = -94, parentFrameViewportHeight) {
                const isInitialCall = !arguments.length;
                //Extremely important. window.innerHeight takes into account the Chrome's mobile address bar when scrolling
                let parentFrameScreenHeight = parentFrameViewportHeight || self.parentViewportHeight;
                if (!parentFrameScreenHeight) {
                    try {
                        // Same domain frames
                        parentFrameScreenHeight = parent.window.innerHeight;
                    } catch (e) {
                        // Cross domain frames
                        if (!isInitialCall) {
                            console.error(
                                'Product details fixed footer align to bottom failed. Missing parent frame height'
                            );
                        }
                    }
                }

                const shouldBeStaticOnPageBottom =
                    scrollTop + parentFrameScreenHeight + 100 > document.body.scrollHeight;

                if (shouldBeStaticOnPageBottom) {
                    bottomFixedElement.style.bottom = 0;
                    bottomFixedElement.style.top = 'auto';
                    bottomFixedElement.style.transform = '';
                } else {
                    // Fixed with calculated Y offset to be placed at the viewport bottom
                    bottomFixedElement.style.setProperty('bottom', 'auto', 'important'); //Otherwise the element will stretch from top 0 till the bottom
                    bottomFixedElement.style.top = 0;
                    bottomFixedElement.style.transform = `translateY(${
                        scrollTop + parentFrameScreenHeight - bottomFixedElement.scrollHeight + overrideOffset
                    }px)`;
                }
            }
        },
        addParentResizeHandler() {
            const resizeHandler = function (e) {
                // Cannot use e.origin due to the numerous frame hosts
                if (!e.data || e.data.event !== 'resizeHeight' || isNaN(e.data.value)) {
                    return;
                }

                this.parentViewportHeight = e.data.value;
            };

            window.addEventListener('message', resizeHandler);
            this.$on('hook:beforeDestroy', () => {
                window.removeEventListener('message', resizeHandler);
            });
        }
    },
    created() {
        if (!this.inIframe) {
            if (process.client) {
                require('@/static/iframe.js');
            }
        } else {
            this.injectTheme();
            this.$cookies.fixIframeCookies();
            this.$nuxt.iframeLayout = this;
        }
    },
    beforeMount() {
        this.initMetaTagsCommunication();
    },
    mounted() {
        /**
         * !Important. In order to access rendered DOM inside <client-only> using mounted hook is not enough.
         *  DOM is not constructed at this stage. $nextTick doesn't help as well.
         *  onNuxtReady magically helps solve this issue
         */
        window.onNuxtReady(() => {
            if (this.inIframe) {
                const targetNode = document.getElementById('main');

                if (this.scrollIFrameOnRoute) {
                    const config = { attributes: false, childList: true, subtree: false };
                    const callback = (mutationsList, observer) => {
                        // Use traditional 'for loops' for IE 11
                        for (let mutation of mutationsList) {
                            if (mutation.type === 'childList') {
                                const id = mutation.target.firstChild.id;
                                if (id && id !== this.previousPageId) {
                                    this.postScrollToParent();
                                    //window.scrollTo(0, 0);
                                    this.previousPageId = id;
                                }
                            }
                        }
                    };

                    this.mutationObserver = new MutationObserver(callback);
                    this.mutationObserver.observe(targetNode, config);
                }

                this.resizeObserver = new ResizeObserver(entries => {
                    clearTimeout(this.resizeTimeout);
                    this.resizeTimeout = setTimeout(() => {
                        const offsetTop = entries[0].target.offsetTop || 0;
                        const offsetBottom = entries[0].target.offsetBottom || 0;
                        this.postResizeToParent(entries[0].contentRect.height + offsetBottom + offsetTop, 0);
                    }, 300);
                });
                this.resizeObserver.observe(targetNode);

                this.$nextTick(() => {
                    this.previousPageId = targetNode.firstElementChild.id;
                    //flex auto doesn't fire resize, main container occupies whole parent height. Need to reset
                    //Should trigger the resizeObserver's callback for the first time
                    targetNode.style.flex = '0';
                });

                this.$root.$scrollToIframeTop = this.postScrollToParent;

                this.addParentResizeHandler();

                this.postParent('ready');
            } else {
                const iframe = document.getElementById(this.iframeID);
                if (iframe) {
                    //for cases when tested without and iframe
                    iframe.style.height = 1000 + 'px';
                }
            }
        });
    },
    beforeDestroy() {
        if (process.client) {
            if (this.inIframe) {
                //be sure that mounted hook ran completely
                this.mutationObserver && this.mutationObserver.disconnect();
                this.resizeObserver && this.resizeObserver.disconnect();
                this.resizeTimeout && clearTimeout(this.resizeTimeout);
                this.removeAfterRouteHook && this.removeAfterRouteHook();
            }
        }
    }
};
