<template>
    <div class="footer-wrapper">
        <footer id="footer" class="container">
            <div class="row footer__sections">
                <section
                    v-for="(section, index) in footerItemsWithMasking"
                    :key="section.title + index"
                    class="col-sm footer-section"
                >
                    <p v-if="section.title" class="footer-heading">
                        {{ section.title }}
                    </p>
                    <ul>
                        <li v-for="(item, index) in section.links" :key="item.text + index" :class="[item.alignment]">
                            <!--Met case when link was undefined-->
                            <Link
                                v-if="item.link"
                                :link="item.link"
                                v-html="$sanitizeHTMLComment(item.text)"
                                :mask="item.shouldMask"
                            />
                            <span v-else v-html="item.text"></span>
                        </li>
                    </ul>
                </section>
            </div>

            <media-only desktop>
                <template v-if="isStartPage">
                    <div v-if="isMainShop" class="row top-hotels">
                        <div class="col-sm-12">
                            <ul>
                                <li>
                                    <span class="title-indent">{{ $t('TopHotels.title') }}:</span>
                                </li>
                                <li>
                                    <NuxtLink
                                        :to="{ name: 'topHotels', params: { start: 1, stop: 25 } }"
                                        class="text-decoration-none"
                                    >
                                        {{ $t('TopHotels.top25') }}
                                    </NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink
                                        :to="{ name: 'topHotels', params: { start: 26, stop: 50 } }"
                                        class="text-decoration-none"
                                    >
                                        {{ $t('TopHotels.placeFromTo', { from: 26, to: 50 }) }}
                                    </NuxtLink>
                                </li>
                                <li>
                                    <NuxtLink
                                        :to="{ name: 'topHotels', params: { start: 51, stop: 100 } }"
                                        class="text-decoration-none"
                                    >
                                        {{ $t('TopHotels.placeFromTo', { from: 51, to: 100 }) }}
                                    </NuxtLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="seoLinks.length" class="row seolinks">
                        <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <ul>
                                <li v-for="(seolink, index) in seoLinks" :key="'seolink' + index" class="seo-link">
                                    <Link :link="seolink.link" class="text-decoration-none" mask>
                                        {{ seolink.text }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </media-only>

            <FooterLegalInfo />
        </footer>
    </div>
</template>

<script>
import navigationItemsMasking from '@/mixins/navigationItemsMasking';
import FooterLegalInfo from '@/components/FooterLegalInfo';

export default {
    name: 'Footer',
    components: { FooterLegalInfo },
    mixins: [navigationItemsMasking],
    computed: {
        seoLinks() {
            return this.$store.state.globalMeta.seoLinks || [];
        },
        isStartPage() {
            return this.$route.name === 'index' || this.$getSafe(this.$store.getters, 'page.startpage');
        },
        isMainShop() {
            return this.$store.getters.isMainShop;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

footer#footer {
    background-color: var(--footer-bg-color);
    color: var(--footer-header-color);

    padding: variables.$grid-gutter-width;
    margin-top: variables.$grid-gutter-width;

    .footer__sections {
        gap: 15px 0;
    }

    section {
        text-align: left;

        .footer-heading {
            margin-top: 0;
            font-size: 18px;
            margin-bottom: 20px;
            font-weight: 600;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 12px;

                &:only-child {
                    margin-bottom: 0;
                }

                &.right {
                    text-align: right;
                }
                &.center {
                    text-align: center;
                }
            }

            a,
            span {
                color: var(--footer-link-color);
                text-decoration: none;
            }
        }
    }

    .seolinks,
    .top-hotels {
        text-align: center;
        margin-top: 20px;
        font-size: 12px;

        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;

            a,
            span {
                color: var(--footer-link-color-darker);
                padding: 0 5px;
            }
        }
    }

    .title-indent {
        margin: 0 5px;
    }
}
</style>
