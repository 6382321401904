<template>
    <div id="animod" :class="[priceModeCSS]">
        <main id="main">
            <nuxt />
        </main>

        <FloatingBottom />
    </div>
</template>

<script>
import Header from '@/components/header/Header';
import MainHeaderMobile from '@/components/header/MainHeaderMobile';
import Navigation from '@/components/navigation/Navigation';
import Footer from '@/components/Footer';
import CheckoutFooter from '@/components/CheckoutFooter';
import authorizedLayout from '@/mixins/authorizedLayout';
import priceModeMixin from '@/mixins/priceModesGlobal';
import translatableLayout from '@/mixins/translatableLayout';
import themeableLayout from '@/mixins/themeableLayout';
import ToTopButton from '@/components/ToTopButton';
import PriceModeToggle from '@/components/PriceModeToggle';
import FloatingBottom from '@/components/layout/FloatingBottom';
import Newsletter from '@/components/newsletter/Newsletter';
import HeaderMagazine from '@/themes/netto-discount/components/HeaderMagazine.vue';
import NettoTopBar from '@/themes/netto-discount/components/NettoTopBar.vue';
import NettoFooter from '@/themes/netto-discount/components/NettoFooter.vue';
import iframeLayout, { middleware } from '@/mixins/iframeLayout';

export default {
    name: 'NettoCombinedLayout',

    mixins: [priceModeMixin, authorizedLayout, translatableLayout, themeableLayout, iframeLayout],

    components: {
        NettoFooter,
        NettoTopBar,
        HeaderMagazine,
        Newsletter,
        FloatingBottom,
        PriceModeToggle,
        Header,
        MainHeaderMobile,
        Navigation,
        Footer,
        CheckoutFooter,
        ToTopButton
    },

    middleware(context) {
        middleware(context, 'netto-frameset.html');
    },

    data() {
        return {
            iframeOffsetInParent: 190,
            scrollIFrameOnRoute: true // tested false, looks worse: upon page change you appear at the prev scroll position
        };
    },

    mounted() {
        this.frameAppendRouteChangeHandler();
        // Uncheck if replaceState does not fit. THen carefully check origins inside handler
        //this.frameAppendBackMessageHandler();
    }
};
</script>
