<template>
    <a
        href="https://www.netto-online.de/ueber-netto/Online-Prospekte.chtm?initfb=reisemagazin"
        target="_blank"
        class="netto-header-magazine"
        title="Online Reisemagazin"
        aria-label="Online Reisemagazin"
    >
        <span class="nr nr-magazines text-3xl">
            <img src="@/themes/netto-discount/assets/Magazine.png" alt="Magazine" width="30" height="30" />
        </span>
        <span class="hidden lg:inline">Reisemagazine</span>
    </a>
</template>

<script>
export default {
    name: 'HeaderMagazine'
};
</script>

<style scoped lang="scss">
.netto-header-magazine {
    color: var(--body-color);
    text-decoration: none;
    font-size: 14px;

    &:hover {
        color: var(--primary);
    }
}
</style>
