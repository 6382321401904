<template>
    <div class="netto-top-bar flex items-center justify-end">
        <div class="py-2 text-sm text-white">Kontakt: 9 - 20 Uhr</div>
        <div class="separator"></div>
        <div class="py-2 text-sm text-white">
            Hotline:
            <a class="hover:underline" href="tel:030 - 606 900 20">030 - 606 900 20</a>
        </div>
        <a class="ml-4 px-3 dc-image" href="https://www.netto-reisen.de/deutschlandcard">
            <img class="h-[30px] w-auto" width="88px" height="34px" :src="dcIcon" alt="DeutschlandCard"
        /></a>
    </div>
</template>

<script>
import dcIcon from '@/themes/netto-discount/assets/dc-logo-white-936c52aa.svg';

export default {
    data() {
        return {
            dcIcon
        };
    }
};
</script>

<style scoped lang="scss">
.netto-top-bar {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;

    a {
        color: white;
    }

    .separator {
        width: 1px;
        height: 1rem;
        background-color: white;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .dc-image {
        background-color: rgb(86, 36, 111);
    }
}
</style>
